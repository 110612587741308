






















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import SlideDeckResourceList from '@/views/products/summary-slides/@components/SlideDeckResourceList.vue'
import SlideDeckAssignResourcesToPage from './SlideDeckAssignResourcesToPage.modal.vue'
import SlideOptions from '@/views/products/summary-slides/@components/SlideOptions.vue'
import Resources, { ResourceType } from '@/store/modules/resource'
import { Market } from '@/store/modules/market'

@Component({
  components: {
    SlideDeckResourceList,
    SlideDeckAssignResourcesToPage,
    SlideOptions
  }
})
export default class SlideDeckManageResources extends Vue {
  show: boolean = false
  @Prop(String) currentPageKey!: string
  @Prop(Number) currentPageNumber!: number
  @Prop() currentPageResources!: Resources[]
  @Prop() permittedMarkets!: Market[]

  get resourceCount () {
    return this.currentPageResources ? this.currentPageResources.length : 0
  }

  get resourceTypes () {
    return this.$store.getters.getResourceTypes.sort((a: ResourceType, b: ResourceType) => {
      return (a.name === 'References' || a.name < b.name) ? -1 : a.name > b.name ? 1 : 0
    })
  }

  toggleResources (): void {
    this.show = !this.show
  }

  async openResourceAssignmentModal (resourceTypeKey: string): Promise<void> {
    // console.log('open model', resourceTypeKey)
    await this.$store.dispatch('loadProductResources', this.$store.getters.getActiveProduct.key)
    this.$bvModal.show('assign-resources-modal')
  }

  @Emit('addResourceToPage')
  addResourceToPage (data: any) {
    return data
  }

  @Emit('removeResourceFromPage')
  removeResourceFromPage (data: any) {
    return data
  }
}
