












import { Component, Vue } from 'vue-property-decorator'
import SlideDeckViewer from './@components/SlideDeckViewer.vue'
import SlideDeckAdminHeader from './@components/SlideDeckAdminHeader.vue'
import store from '@/store/store'
import { Product } from '@/store/modules/product'
import { SlideDeck } from '@/store/modules/slideDeck'

@Component({
  components: {
    SlideDeckViewer,
    SlideDeckAdminHeader
  }
})
export default class SummarySlidesDashboard extends Vue {
  slideDeck: SlideDeck = this.$store.getters.getActiveSlideDeck
  documentError?: string = undefined

  mounted () {
    this.$store.dispatch('loadResourceTypes')
    this.slideDeck = this.$store.getters.getActiveSlideDeck
  }

  onDocumentErrored (e: any) {
    this.documentError = e.text
  }

  async beforeRouteUpdate (to: any, from: any, next: Function) {
    const _product: Product = await store.dispatch('loadProductAdminIncDisabled', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.slideDeckKey) {
      if (!_product.slideDecks || !_product.slideDecks.length) {
        return next(`/admin/product/${_product.key}`)
      }
      return next(`/admin/product/${_product.key}/summary-slides/${_product.slideDecks[0].key}`)
    }
    // load resources so that tab menu can update acordingly
    await store.dispatch('loadProductResources', to.params.productKey)
    const _slideDeck: SlideDeck = await store.dispatch('loadSlideDeckAdmin', to.params.slideDeckKey)
    if (_slideDeck) {
      await store.dispatch('loadSlideDeckSections', _slideDeck.slideDeckSections)
      await store.dispatch('loadSlideDeckPages', _slideDeck.slideDeckSections)
      this.slideDeck = this.$store.getters.getActiveSlideDeck
      return next()
    }
    next(`/admin/product/${_product.key}`)
  }

  async beforeRouteEnter (to: any, from: any, next: Function) {
    const _product: Product = await store.dispatch('loadProductAdminIncDisabled', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    if (!to.params.slideDeckKey) {
      if (!_product.slideDecks || !_product.slideDecks.length) {
        return next(`/admin/product/${_product.key}`)
      }
      return next(`/admin/product/${_product.key}/summary-slides/${_product.slideDecks[0].key}`)
    }
    // load resources so that tab menu can update acordingly
    await store.dispatch('loadProductResources', to.params.productKey)
    const _slideDeck: SlideDeck = await store.dispatch('loadSlideDeckAdmin', to.params.slideDeckKey)
    if (_slideDeck) {
      await store.dispatch('loadSlideDeckSections', _slideDeck.slideDeckSections)
      await store.dispatch('loadSlideDeckPages', _slideDeck.slideDeckSections)
      return next()
    }
    next(`/admin/product/${_product.key}`)
  }
}
