















import { Component, Vue, Prop } from 'vue-property-decorator'
import { SlideDeck } from '@/store/modules/slideDeck'
import SlideDeckPublishModal from './SlideDeckPublish.modal.vue'
import { EventBus } from '@/utils/event-bus'

@Component({
  components: {
    SlideDeckPublishModal
  }
})
export default class SlideDeckAdminHeader extends Vue {
  productName: string = this.$store.getters.getActiveProduct.name
  @Prop() slideDeck!: SlideDeck
  @Prop() breadcrumbItemOverride?: any[]
  breadcrumbItems: any[] = [
    {
      text: 'Home',
      to: { name: 'home' }
    },
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      html: `${this.productName}`,
      to: { name: 'product-admin' }
    },
    {
      html: `${this.slideDeck.title || ''} (v${this.slideDeck.semVersion || ''})`,
      to: { name: 'summary-slides-admin' }
    }
  ]

  openPublishModal () {
    this.$bvModal.show('slide-deck-publish-modal')
  }

  publish (updateDetails: any) {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to publish?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      this.$bvModal.hide('slide-deck-publish-modal')
      this.$store.dispatch('publishSlideDeck', { slideDeck: this.slideDeck, updateDetails: updateDetails }).then((newDraft: SlideDeck) => {
        EventBus.$emit('hide-loader')
        this.$router.push({ name: 'summary-slides-admin', params: { productKey: this.$store.getters.getActiveProduct.key, slideDeckKey: newDraft.key } })
        this.$bvModal.msgBoxOk('Publish complete', {
          size: 'sm',
          buttonSize: 'sm',
          okTitle: 'OK',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
      }).catch(() => {
        EventBus.$emit('hide-loader')
        this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
      })
    })
  }
}
