











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Resource, ResourceType, SlideDeckResourceNumber } from '@/store/modules/resource'
import { Market } from '@/store/modules/market'

@Component
export default class SlideDeckAssignResourcesToPage extends Vue {
  @Prop(String) currentPageKey!: string
  @Prop() currentPageResources!: Resource[]
  @Prop() permittedMarkets!: Market[]

  get allResources (): Resource[] {
    return this.$store.getters.getAllProductResources.filter((r: Resource) => {
      // filter to only show those resources assigned a market that is also assigned to this gvd
      return this.permittedMarkets.some((market: Market) => {
        return r.markets.map((m: Market) => { return m.key }).includes(market.key)
      })
    })
  }

  get resourceTypes (): ResourceType[] {
    return this.$store.getters.getResourceTypes
  }

  resourceIsPermittedForMarket (marketKey: string) {
    return this.permittedMarkets.some((market: Market) => {
      return market.key === marketKey
    })
  }

  resourcesForType (resourceTypeKey: string) {
    let _resources: Resource[] = JSON.parse(JSON.stringify(this.allResources))

    // if there isn't a category, show resources which none
    if (!resourceTypeKey) {
      return _resources.filter((r: any) => {
        return !r.resourceType
      }).sort((a: Resource, b: Resource) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0 })
    }

    // only show resources for this category
    _resources = _resources.filter((r: Resource) => {
      return r.resourceType ? r.resourceType.key === resourceTypeKey : false
    }).map((r: Resource) => {
      const _slideDeckResourceNoForSlideDeck = r.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => (this.$store.getters.getActiveSlideDeck.publishedEntity && rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.publishedEntity.key))
      if (_slideDeckResourceNoForSlideDeck) {
        r.name = `${_slideDeckResourceNoForSlideDeck.number}. ${r.name}`
      }
      return r
    })

    // now sort by ref number and then by name, if not a reference
    const _publishedEntityKey: string = this.$store.getters.getActiveSlideDeck.publishedEntity ? this.$store.getters.getActiveSlideDeck.publishedEntity.key : ''
    _resources = _resources.sort((a: Resource, b: Resource) => {
      let _aResNum = -1
      let _bResNum = -1
      if (a.slideDeckResourceNumbers && a.slideDeckResourceNumbers.length) {
        if (_publishedEntityKey.length) {
          _aResNum = a.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === _publishedEntityKey)?.number as number
          _bResNum = b.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === _publishedEntityKey)?.number as number
        } else {
          _aResNum = a.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.key)?.number as number
          _bResNum = b.slideDeckResourceNumbers.find((rn: SlideDeckResourceNumber) => rn.slideDeck.key === this.$store.getters.getActiveSlideDeck.key)?.number as number
        }
      }
      if (_aResNum > _bResNum && (_bResNum > 0)) {
        return 1
      } else if (_aResNum < _bResNum && (_aResNum > 0)) {
        return -1
      }

      // else sort by name
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
    })
    return _resources
  }

  resourceIsAssigned (resourceKey: string) {
    if (this.currentPageResources.findIndex((r: Resource) => {
      return r.key === resourceKey
    }) >= 0) {
      return true
    }
    return false
  }

  @Emit('addResourceToPage')
  addToPage (resourceKey: string) {
    return { resourceKey, pageKey: this.currentPageKey }
  }

  @Emit('removeResourceFromPage')
  removeFromPage (resourceKey: string) {
    return { resourceKey, pageKey: this.currentPageKey }
  }
}
